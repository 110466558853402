import { z } from "zod";

export const SuccessSchema = z.object({
  isSuccess: z.boolean(),
  message: z.string(),
});

export function generateEventSlug(eventName: string): string {
  return eventName
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
}

export function generateFestivalsSlug(festivalsName: string): string {
  return festivalsName
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
}

export function generateBlogsSlug(blogsName: string): string {
  return blogsName
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
}

export const sanityProject = {
  projectId: "i07149v6",
  dataset: "production",
};
