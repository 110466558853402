import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import {
  AddEventRequest,
  EventRequestResponse,
  EventUpdateRequest,
  GetAllEventsResponse,
  GetEventPageDetailsResponse,
  GetEventResponse,
  GetEventResponses,
  EventTypeCountResponse,
} from "./types";
import { EventViewType } from "contract/enum";
import { createPaginatedResponseSchema } from "contract/utils";

const c = initContract();

export const eventContract = c.router(
  {
    createEvent: {
      method: "POST",
      path: "/",
      body: AddEventRequest,
      responses: {
        200: SuccessSchema,
      },
    },
    getEvent: {
      method: "GET",
      path: "/",
      query: z.object({
        eventId: z.string(),
      }),
      responses: {
        200: GetEventResponse,
      },
    },
    updateEvent: {
      method: "PUT",
      path: "/",
      body: EventUpdateRequest,
      responses: {
        200: SuccessSchema,
      },
    },
    getAllEvents: {
      method: "GET",
      path: "/all",
      query: z.object({
        eventViewType: z.nativeEnum(EventViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllEventsResponse),
      },
    },
    adminGetAllEvents: {
      method: "GET",
      path: "/allbyAdmin",
      query: z.object({
        eventViewType: z.nativeEnum(EventViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllEventsResponse),
      },
    },
    getEventResponses: {
      method: "GET",
      path: "/responses",
      query: z.object({
        eventId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetEventResponses),
      },
    },
    getEventPageDetails: {
      method: "GET",
      path: "/pageDetails",
      query: z.object({
        eventId: z.string(),
        inviteId: z.string().optional(),
      }),
      responses: {
        200: GetEventPageDetailsResponse,
      },
    },
    addEventRequestResponse: {
      method: "POST",
      path: "/response",
      body: EventRequestResponse,
      responses: { 200: SuccessSchema.extend({ calendarLink: z.string() }) },
    },
    getEventTypeCount: {
      method: "GET",
      path: "/typeCount",
      responses: {
        200: EventTypeCountResponse,
      },
    },
    adminGetEventTypeCount: {
      method: "GET",
      path: "/typeCountByAdmin",
      responses: {
        200: EventTypeCountResponse,
      },
    },
  },
  {
    pathPrefix: "/event",
  }
);
