import { initQueryClient } from "@ts-rest/react-query";
import { getApiUrl } from "@/env";
import { contract } from "contract";
import axios, {
  AxiosError,
  AxiosResponse,
  isAxiosError,
  AxiosRequestConfig,
  isCancel,
  CanceledError,
} from "axios";
import { ErrorInfo } from "react";

const queryClient = (config?: AxiosRequestConfig<any>) =>
  initQueryClient(contract, {
   baseUrl: getApiUrl(),
    //baseUrl: "http://localhost:3000/api",
    baseHeaders: {},
    api: async ({ path, method, headers, body }) => {
      try {
        const result = await axios({
          method: method,
          url: path,
          headers,
          data: body,
          ...config,
        });
        return {
          status: result.status,
          body: result.data,
          headers: result.headers as any,
        };
      } catch (e: Error | AxiosError | any) {
        if (
          isCancel(e) &&
          e instanceof CanceledError &&
          e.config?.signal instanceof AbortSignal
        ) {
          throw new Error(e.config?.signal?.reason ?? "Request Cancelled");
        }
        if (isAxiosError(e)) {
          const error = e as AxiosError;
          const response = error.response as AxiosResponse;
          return {
            status: response.status,
            body: response.data,
            headers: response.headers,
          };
        }
        throw e;
      }
    },
  });
export const getQueryClient = (config?: AxiosRequestConfig<any>) => {
  return queryClient(config);
};
export const logRenderError = (error: Error, errorInfo: ErrorInfo) => {
  console.error("An error occurred in a child component:", error, errorInfo);
};
