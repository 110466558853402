import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import {
  commonblogsObj,
  BlogsRequestResponse,
  GetAllBlogsResponse,
  GetBlogsPageDetailsResponse,
  GetBlogsResponse,
  GetBlogsResponses,
  BlogsTypeCountResponse,
} from "./types";
import { BlogsViewType } from "contract/enum";
import { createPaginatedResponseSchema } from "contract/utils";

const c = initContract();

export const blogsContract = c.router(
  {
    createBlogs: {
      method: "POST",
      path: "/",
      body: commonblogsObj,
      responses: {
        200: SuccessSchema,
      },
    },
    getBlogs: {
      method: "GET",
      path: "/",
      query: z.object({
        blogsId: z.string(),
      }),
      responses: {
        200: GetBlogsResponse,
      },
    },
    updateBlogs: {
      method: "PUT",
      path: "/",
      body: GetBlogsResponse,
      responses: {
        200: SuccessSchema,
      },
    },
    getAllBlogs: {
      method: "GET",
      path: "/all",
      query: z.object({
        blogsViewType: z.nativeEnum(BlogsViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllBlogsResponse),
      },
    },
    adminGetAllBlogs: {
      method: "GET",
      path: "/allbyAdmin",
      query: z.object({
        blogsViewType: z.nativeEnum(BlogsViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllBlogsResponse),
      },
    },
    getBlogsResponses: {
      method: "GET",
      path: "/responses",
      query: z.object({
        blogsId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetBlogsResponses),
      },
    },
    getBlogsPageDetails: {
      method: "GET",
      path: "/pageDetails",
      query: z.object({
        blogsId: z.string(),
        inviteId: z.string().optional(),
      }),
      responses: {
        200: GetBlogsPageDetailsResponse,
      },
    },
    addBlogsRequestResponse: {
      method: "POST",
      path: "/response",
      body: BlogsRequestResponse,
      responses: { 200: SuccessSchema },
    },
    getBlogsTypeCount: {
      method: "GET",
      path: "/typeCount",
      responses: {
        200: BlogsTypeCountResponse,
      },
    },
    adminGetBlogsTypeCount: {
      method: "GET",
      path: "/typeCountByAdmin",
      responses: {
        200: BlogsTypeCountResponse,
      },
    },
  },
  {
    pathPrefix: "/blogs",
  }
);
