import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { MantineProvider } from "@mantine/core";
import { customTheme } from "@/root/theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import { PageLoaderProvider } from "@/contexts/PageLoaderProvider";
import { useMediaQuery } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { useEffect } from "react";
import { LoginProvider } from "@/contexts/LoginProvider";
import { UserDataProvider } from "@/contexts/UserProvider";
import { ScreenBreakpointsProvider } from "@/contexts/ScreenBreakpointsProvider";
import { ChatProvider } from "@/contexts/ChatProvider";
import AccountDeletionModalContainer from "@/contexts/AccountDeletionModalContainer";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getEnv, getPostHogApiKey } from "@/env";
import ErrorBoundary from "@/components/ErrorBoundary";
import Head from "next/head";
import "@mantine/carousel/styles.css";
import "@mantine/tiptap/styles.css";
import "@/css/mainboard.min.css";
import "@/styles/sass/main.scss";

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  const isMobile = useMediaQuery("(max-width: 479px)");

  const isTablet = useMediaQuery("(min-width: 480px) and (max-width: 768px)");

  useEffect(() => {
    import("posthog-js").then((posthog) => {
      posthog.default.init(getPostHogApiKey(), {
        api_host: "/ingest",
        ui_host: "<ph_app_host>",
      });
    });
  }, []);

  return (
    <>
      {getEnv() === "prod" && (
        <Head>
          {/* Google tag (gtag.js) */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-NJCT89YSFL"
          ></script>
          {/* <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-NJCT89YSFL');
            `}
          </script> */}
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-NJCT89YSFL');
              `,
            }}
          />
        </Head>
      )}
      <ErrorBoundary>
        <MantineProvider theme={customTheme}>
          <QueryClientProvider client={queryClient}>
            <Notifications
              limit={2}
              position={isMobile || isTablet ? "top-center" : "bottom-right"}
              autoClose={5000}
            />
            <ScreenBreakpointsProvider>
              <LoginProvider>
                <UserDataProvider>
                  <ModalsProvider>
                    <PageLoaderProvider>
                      <ChatProvider>
                        <AccountDeletionModalContainer>
                          <Component {...pageProps} />
                        </AccountDeletionModalContainer>
                      </ChatProvider>
                    </PageLoaderProvider>
                  </ModalsProvider>
                </UserDataProvider>
              </LoginProvider>
            </ScreenBreakpointsProvider>
          </QueryClientProvider>
        </MantineProvider>
      </ErrorBoundary>
    </>
  );
}
