const publicRuntimeConfig =
  require("next/config").default().publicRuntimeConfig;

const {
  apiUrl,
  env,
  socketUrl,
  backendUrl,
  frontendUrl,
  s3BucketUrl,
  postHogApiKey,
} = publicRuntimeConfig;
export const getApiUrl = () => {
  console.log("getApiUrl" + apiUrl)
  return apiUrl;
  
};

export const getEnv = () => {
  console.log("env" + env)
  return env;
};

export const getSocketUrl = () => {
  console.log("socketUrl" + socketUrl)
  return socketUrl;
};

export const getFrontendUrl = () => {
  console.log("frontendUrl" + frontendUrl)
  return frontendUrl;
};

export const getBackendUrl = () => {
  console.log("backendUrl" + backendUrl)
  return backendUrl;
};
export const getS3BucketUrl = () => {
  console.log("s3BucketUrl" + s3BucketUrl)
  return s3BucketUrl;
};

export const getPostHogApiKey = () => {
  console.log("getApiUrl" + postHogApiKey)
  return postHogApiKey;
};

