import { initContract } from "@ts-rest/core";
import { authContract } from "./auth/contract";
import { vendorContract } from "./vendor/contract";
import { mediaContract } from "./media/contract";
import { userContract } from "./user/contract";
import { chatContract } from "./chat/contract";
import { weddingContract } from "./wedding/contract";
import { budgetContract } from "./budget/contract";
import { timelineContract } from "./timeline/contract";
import { notificationContract } from "./notification/contract";
import { imageSearchContract } from "./imageSearch/contract";
import { blogContract } from "./blog/contract";
import { eventContract } from "./event/contract";
import { festivalsContract } from "./festivals/contract";
import { blogsContract } from "./blogs/contract";


const c = initContract();

export const contract = c.router({
  auth: authContract,
  vendor: vendorContract,
  media: mediaContract,
  user: userContract,
  chat: chatContract,
  wedding: weddingContract,
  budget: budgetContract,
  timeline: timelineContract,
  notification: notificationContract,
  imageSearch: imageSearchContract,
  blog: blogContract,
  event: eventContract,
  festivals: festivalsContract,
  blogs:blogsContract,
});
