export const acceptableImageTypes = [".png", ".jpg", ".jpeg", ".heic", ".webp"];

export enum LoginDevice {
  mobile = "mobile",
  desktop = "desktop",
  tablet = "tablet",
}

export enum LoginStatus {
  loggedIn = "loggedIn",
  loggedOut = "loggedOut",
}

export enum MediaType {
  image = "image",
  video = "video",
  pdf = "pdf",
  audio = "audio",
}

export enum UserRoleEnum {
  proPlanner = "proPlanner",
  collaborator = "collaborator",
  spouse = "spouse",
  planner = "planner",
}

export enum InviteStatus {
  accepted = "accepted",
  pending = "pending",
}

export enum RoomType {
  private = "private",
  group = "group",
}

export enum ReactionsEnum {
  smile = "&#128512;",
  hehe = "&#128513;",
  hahaha = "&#128514;",
  awkward = "&#128515;",
  funny = "&#128516;",
  awksSmile = "&#128517;",
}

export enum ImageCategory {
  globallyAvailable = "globallyAvailable",
  coverImage = "coverImage",
  Glamorous = "Glamorous",
  Traditional = "Traditional",
  Outdoor = "Outdoor",
  "Beach Getaway" = "Beach Getaway",
  Minimalist = "Minimalist",
  Rustic = "Rustic",
}

export enum SystemAlbumCategory {
  "Beach Getaway" = ImageCategory["Beach Getaway"],
  Rustic = ImageCategory.Rustic,
  Glamorous = ImageCategory.Glamorous,
  Traditional = ImageCategory.Traditional,
  Outdoor = ImageCategory.Outdoor,
  Minimalist = ImageCategory.Minimalist,
}

export enum UserChatStatus {
  online = "online",
  offline = "offline",
  unavailable = "unavailable",
}

export enum TimelineType {
  months6 = "months6",
  months8 = "months8",
  months12 = "months12",
}

export enum Month {
  january = "january",
  february = "february",
  march = "march",
  april = "april",
  may = "may",
  june = "june",
  july = "july",
  august = "august",
  september = "september",
  october = "october",
  november = "november",
  december = "december",
}

export const MonthOrder = {
  [Month.january]: 0,
  [Month.february]: 1,
  [Month.march]: 2,
  [Month.april]: 3,
  [Month.may]: 4,
  [Month.june]: 5,
  [Month.july]: 6,
  [Month.august]: 7,
  [Month.september]: 8,
  [Month.october]: 9,
  [Month.november]: 10,
  [Month.december]: 11,
};

export enum TimelineView {
  monthly = "monthly",
  category = "category",
}

export enum NotificationType {
  userAddedToProject = "userAddedToProject",
  userInvitedToProject = "userInvitedToProject",
  taskAdded = "taskAdded",
  taskCompleted = "taskCompleted",
  dueDateIn1Week = "dueDateIn1Week",
  dueDateIn2Days = "dueDateIn2Days",
  dueDateChanged = "dueDateChanged",
  userRequestedMeeting = "vendorMeetingAdded",
  vendorMeetingStatusChanged = "vendorMeetingStatusChanged",
  vendorMeetingTaskDeleted = "vendorMeetingTaskDeleted",
  vendorMeetingTaskMarkCompleted = "vendorMeetingTaskMarkCompleted",
  userDeletedMeeting = "userDeletedMeeting",
  userMeetingTaskCompleted = "userMeetingTaskCompleted",
  proPlannerVendorInviteRequestAccepted = "proPlannerVendorInviteRequestAccepted",
}

export enum WeddingCreationRoute {
  haveAWeddingDate = "haveAWeddingDate",
  dontHaveAWeddingDate = "dontHaveAWeddingDate",
}

export enum WeddingFlowConversionRoute {
  archiveAndStartFresh = "archiveAndStartFresh",
  manuallyAdjustDate = "manuallyAdjustDate",
}

export enum GuestCapacity {
  lessThan100 = "lessThan100",
  between100And500 = "between100And500",
  between500And1000 = "between500And1000",
  between1000And2000 = "between1000And2000",
  between2000And3000 = "between2000And3000",
  between3000And5000 = "between3000And5000",
  moreThan5000 = "moreThan5000",
}

export enum PriceRange {
  below1000 = "below1000",
  between1000And2000 = "between1000And2000",
  between2000And3000 = "between2000And3000",
  between3000And4000 = "between3000And4000",
  between5000And10000 = "between5000And10000",
  above10000 = "above10000",
  contactForPrice = "contactForPrice",
}

export enum SpecialCategoryType {
  keymoments = "keymoments",
  ceremony = "ceremony",
  vendorMeeting = "vendorMeeting",
}

export enum SpecialTaskType {
  weddingDateTask = "weddingDateTask",
  vendorMeeting = "vendorMeeting",
}

export enum RequestStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
}

export enum VendorTypeEnum {
  Vendor = "vendor",
  ProPlanner = "proPlanner",
}

export enum ProPlannerView {
  userSide = "userSide",
  vendorSide = "vendorSide",
}
export enum StarRatingEnum {
  FiveStars = 5,
  FourStars = 4,
  ThreeStars = 3,
  TwoStars = 2,
  OneStar = 1,
}

export enum QuestionType {
  multipleChoiceMcq = "multipleChoiceMcq",
  singleChoiceMcq = "singleChoiceMcq",
  text = "text",
}

export enum EventType {
  online = "online",
  offline = "offline",
}

export enum EventViewType {
  upcoming = "upcoming",
  past = "past",
  all = "all",
}

export enum EventOrWeddingPathChosen {
  eventPath = "eventPath",
  weddingPath = "weddingPath",
}


export enum FestivalsType {
  online = "online",
  offline = "offline",
}

export enum FestivalsViewType {
  upcoming = "upcoming",
  past = "past",
  all = "all",
}

export enum FestivalsOrWeddingPathChosen {
  festivalsPath = "festivalsPath",
  weddingPath = "weddingPath",
}

export enum BlogsType {
  online = "online",
  offline = "offline",
}

export enum BlogsViewType {
  upcoming = "upcoming",
  past = "past",
  all = "all",
}


