import { FestivalsType, QuestionType } from "contract/enum";
import { stringToDateConvertor } from "contract/utils";
import { z } from "zod";

const MCQSingleChoiceAnswers = z.object({
  questionType: z.literal(QuestionType.singleChoiceMcq),
  answer: z.array(z.string()),
});

const MCQMultipleChoiceAnswers = z.object({
  questionType: z.literal(QuestionType.multipleChoiceMcq),
  answer: z.array(z.string()),
});

const TextAnswers = z.object({
  questionType: z.literal(QuestionType.text),
  answer: z.string(),
});

export const AnswerType = z.discriminatedUnion("questionType", [
  MCQSingleChoiceAnswers,
  MCQMultipleChoiceAnswers,
  TextAnswers,
]);

export type AllAnswerType = z.infer<typeof AnswerType>;

export const commonfestivalsObj = z.object({
  title: z.string().max(1000),
  festivalsType: z.nativeEnum(FestivalsType),
  meetLink: z.string().optional(),
  hostName: z.string(),
  meetingTime: z
    .array(
      z.object({
        startTime: z.string().transform(stringToDateConvertor),
        endTime: z.string().transform(stringToDateConvertor),
      })
    )
    .min(1),
  venue: z.string().max(500),
  description: z.string().max(1500),
  question: z.array(
    z.object({
      id: z.string().optional(),
      title: z.string().max(500),
      isRequired: z.boolean(),
      questionType: z.nativeEnum(QuestionType),
      options: z.array(z.string()).optional(),
    })
  ),
  coverPhoto: z.string(),
  inviteGuests: z.array(z.object({ email: z.string() })),
});
export const AddFestivalsRequest = commonfestivalsObj.refine(
  (festivals) => {
    if (festivals.festivalsType === FestivalsType.online) {
      return festivals.meetLink !== undefined;
    }
    return true;
  },
  {
    message: "Meeting link is required for online users",
    path: ["meetLink"],
  }
);

export const GetFestivalsResponse = commonfestivalsObj.extend({ id: z.string() });

export const GetAllFestivalsResponse = z.object({
  id: z.string(),
  festivalsName: z.string(),
  startDate: z.array(z.date()),
  invitedCount: z.number(),
});

export const GetFestivalsResponses = z.object({
  username: z.string(),
  questionAnswers: z.array(
    z.object({
      questionTitle: z.string(),
      answer: AnswerType,
      options: z.array(z.string()).nullable(),
    })
  ),
});

export const GetFestivalsPageDetailsResponse = z.object({
  id: z.string(),
  title: z.string(),
  festivalsDates: z.array(
    z.object({
      startTime: z.date(),
      endTime: z.date(),
    })
  ),
  description: z.string(),
  venue: z.string(),
  coverPhoto: z.string(),
  invitedEmail: z.string().optional(),
  calenderInviteLink: z.string().optional(),
  isFestivalsCreator: z.boolean(),
  question: z.array(
    z.object({
      id: z.string(),
      title: z.string().max(500),
      isRequired: z.boolean(),
      questionType: z.nativeEnum(QuestionType),
      options: z.array(z.string()).optional(),
    })
  ),
});

export const FestivalsRequestResponse = z.object({
  festivalsId: z.string(),
  inviteId: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  areYouAbleToAttend: z.boolean(),
  invitedEmail: z.string(),
  questionAnswers: z.array(
    z.object({
      questionId: z.string(),
      answer: AnswerType,
    })
  ),
});

export const FestivalsUpdateRequest = GetFestivalsResponse.omit({
  meetingTime: true,
  question: true,
  hostName: true,
});

export const FestivalsTypeCountResponse = z.object({
  allFestivalsCount: z.number(),
  upcomingFestivalsCount: z.number(),
  pastFestivalsCount: z.number(),
});
