import { ImageCategory, MediaType } from "contract/enum";
import { string, z } from "zod";

const MAX_FILE_SIZE = 2000000;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/heic",
];

export const AddAlbumRequest = z.object({
  projectId: z.string(),
  albumName: z.string().max(20),
});

export const AddAlbumResponse = z.object({
  id: z.string(),
  albumName: z.string().max(20),
});

export const AddMediaResponse = z.object({
  id: z.string(),
  mediaURL: z.string(),
  mediaType: z.nativeEnum(MediaType),
});

export const AddAllMediaResponse = z.object({
  medias: z.array(AddMediaResponse),
});

const MediaObjectToAdd = z
  .object({
    themeMediaUrl: z.string(),
    mediaType: z.nativeEnum(MediaType),
    isSystemMedia: z.literal(false),
  })
  .or(
    z.object({
      themeMediaUrl: z.string(),
      mediaType: z.nativeEnum(MediaType),
      isSystemMedia: z.literal(true),
      systemMediaId: z.string(),
    })
  );
//   .refine(
//     (mediaFile) =>
//       mediaFile.isSystemMedia ? new Boolean(mediaFile.systemMediaId) : true,
//     "pass the system media file id"
//   );

export const AddMediaRequest = z
  .object({
    albumId: z.string(),
    projectId: z.string(),
    isSystemMedia: z.literal(false),
    mediaThemes: z.array(
      z.object({
        themeMediaUrl: z.string(),
        mediaType: z.nativeEnum(MediaType),
      })
    ),
  })
  .or(
    z.object({
      albumId: z.string(),
      projectId: z.string(),
      isSystemMedia: z.literal(true),
      mediaThemes: z.array(
        z.object({
          themeMediaUrl: z.string(),
          mediaType: z.nativeEnum(MediaType),
          systemMediaId: z.string(),
        })
      ),
    })
  );

export const AddMediaForNewTheme = z.object({
  url: z.string(),
  key: z.string(),
  type: z.string(),
  isUploaded: z.boolean(),
  message: z.string(),
  originalname: z.string(),
});

export const AllNewMediaForNewTheme = z.object({
  data: z.array(AddMediaForNewTheme),
});

export type AlbumResponse = z.infer<typeof getAlbumsResponse>;

export const getAlbumsResponse = z.object({
  albumName: z.string(),
  id: z.string(),
  mediaSize: z.number(),
  mediaUrls: z.array(
    z.object({
      mediaURL: z.string(),
      mediaType: z.nativeEnum(MediaType),
    })
  ),
});

export const getAlbumsQuery = z.object({
  projectId: z.string().optional(),
  isCategoryView: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
});

export const getMediaItemsResponse = z.object({
  albumId: z.string(),
  albumName: z.string(),
  medias: z.array(
    z.object({
      mediaId: z.string(),
      mediaURL: z.string(),
      mediaType: z.nativeEnum(MediaType),
      isSytemMedia: z.boolean(),
    })
  ),
});

export const getMediaItemsQuery = z.object({
  albumId: z.string(),
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
});

export const DeleteAlbumRequest = z.object({
  albumId: z.string(),
});

export const EditAlbumNameRequest = z.object({
  albumId: z.string(),
  newAlbumName: z.string(),
});

export const DeleteMediaRequest = z.object({
  mediaId: z.string(),
});

export const GetSystemMediaItemRequest = z.object({
  projectId: z.string(),
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
  searchText: z.string().nullable(),
 
});

export const AddSystemMediaItemRequest = z.object({
  systemMediaFiles: z.array(
    z.object({
      imageURL: z.string(),
      imageCategory: z.nativeEnum(ImageCategory),
    })
  ),
});

export const GetAlbumsForSystemMediaFilesRequest = z.object({
  projectId: z.string(),
  systemMediaId: z.string(),
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
});

export const GetAlbumsForSystemMediaFilesResponse = z.object({
  presentAlbum: z
    .object({
      albumName: z.string(),
      albumId: z.string(),
      created_id: z.string().nullable(),
      // created_by_id: z.string().nullable()s,
    })
    .nullable(),
  albumsToAdd: z.array(
    z.object({
      albumName: z.string(),
      albumId: z.string(),
      created_id: z.string().nullable(),
    })
  ),
});

export const GetAlbumsToMoveMediaFilesRequest = z.object({
  mediaId: z.string(),
  projectId: z.string(),
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
});

export const GetAlbumsToMoveMediaFilesResponse = z.object({
  albumId: z.string(),
  albumName: z.string(),
});

export const MoveMediaFilesToOtherAlbumsRequest = z.object({
  albumId: z.string(),
  mediaId: z.string(),
});

export const MediaFileUploadSchema = z.object({
  files: z
    .array(z.custom<File>())
    .refine(
      (files) => {
        return files.every((file) => file instanceof File);
      },
      {
        message: "Expected a file",
      }
    )
    .refine(
      (files) => files.every((file) => file.size <= MAX_FILE_SIZE),
      `File size should be less than 20mb.`
    )
    .refine(
      (files) =>
        files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file.type)),
      "Only these types are allowed .jpg, .jpeg, .png and .heic"
    ),
});

export const MoveSystemMediaFilesToOtherAlbumsRequest = z.object({
  systemMediaId: z.string(),
  projectId: z.string(),
  albumId: z.string(),
});

export const GetTypesenseMediaFilesRequest = z.object({
  projectId: z.string().optional(),
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
  searchText: z.string(),
});

export const GetTypesenseMediaFilesResponse = z.object({
  mediaFiles: z.array(
    z.object({
      mediaId: z.string(),
      systemMediaId: z.string(),
      imageFileURL: z.string(),
      albumsResponse: GetAlbumsForSystemMediaFilesResponse,
    })
  ),
  count: z.number(),
});

export const GetAlbumsToMoveTypeSenseMediaFilesRequest = z.object({
  projectId: z.string(),
  mediaId: z.string().optional(),
});

export const GetAlbumsToMoveTypeSenseMediaFilesResponse = z.object({
  presentAlbum: z
    .object({
      id: z.string(),
      albumName: z.string(),
    })
    .nullable(),
  albumsToAdd: z.array(
    z.object({
      id: z.string(),
      albumName: z.string(),
    })
  ),
});

export const GetSystemMediaItemResponse = z.object({
  systemMediaId: z.string(),
  imageFileURL: z.string(),
  isAddedInAnyAlbum: z.boolean(),
  albumsResponse: GetAlbumsForSystemMediaFilesResponse,
});
