import { BlogsType, QuestionType } from "contract/enum";
import { stringToDateConvertor } from "contract/utils";
import { boolean, z } from "zod";

const MCQSingleChoiceAnswers = z.object({
  questionType: z.literal(QuestionType.singleChoiceMcq),
  answer: z.array(z.string()),
});

const MCQMultipleChoiceAnswers = z.object({
  questionType: z.literal(QuestionType.multipleChoiceMcq),
  answer: z.array(z.string()),
});

const TextAnswers = z.object({
  questionType: z.literal(QuestionType.text),
  answer: z.string(),
});

export const AnswerType = z.discriminatedUnion("questionType", [
  MCQSingleChoiceAnswers,
  MCQMultipleChoiceAnswers,
  TextAnswers,
]);

export type AllAnswerType = z.infer<typeof AnswerType>;

export const commonblogsObj = z.object({
  title: z.string().max(1000),
  description: z.string(),
  coverPhoto: z.string(),
 
});


export const GetBlogsResponse = commonblogsObj.extend({ id: z.string() });

export const GetAllBlogsResponse = z.object({
  id: z.string(),
  blogsName: z.string(),
  blogsDescription: z.string(),
  blogsImage: z.string(),
});

export const GetBlogsResponses = z.object({
  username: z.string(),
  questionAnswers: z.array(
    z.object({
      questionTitle: z.string(),
      answer: AnswerType,
      options: z.array(z.string()).nullable(),
    })
  ),
});

export const GetBlogsPageDetailsResponse = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  coverPhoto: z.string(),

});

export const BlogsRequestResponse = z.object({
  blogsId: z.string(),
  inviteId: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
 
  
 
});


export const BlogsTypeCountResponse = z.object({
  allBlogsCount: z.number(),
  upcomingBlogsCount: z.number(),
  pastBlogsCount: z.number(),
});

