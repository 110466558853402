import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import {
  AddFestivalsRequest,
  FestivalsRequestResponse,
  FestivalsUpdateRequest,
  GetAllFestivalsResponse,
  GetFestivalsPageDetailsResponse,
  GetFestivalsResponse,
  GetFestivalsResponses,
  FestivalsTypeCountResponse,
} from "./types";
import { FestivalsViewType } from "contract/enum";
import { createPaginatedResponseSchema } from "contract/utils";

const c = initContract();

export const festivalsContract = c.router(
  {
    createFestivals: {
      method: "POST",
      path: "/",
      body: AddFestivalsRequest,
      responses: {
        200: SuccessSchema,
      },
    },
    getFestivals: {
      method: "GET",
      path: "/",
      query: z.object({
        festivalsId: z.string(),
      }),
      responses: {
        200: GetFestivalsResponse,
      },
    },
    updateFestivals: {
      method: "PUT",
      path: "/",
      body: FestivalsUpdateRequest,
      responses: {
        200: SuccessSchema,
      },
    },
    getAllFestivals: {
      method: "GET",
      path: "/all",
      query: z.object({
        festivalsViewType: z.nativeEnum(FestivalsViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllFestivalsResponse),
      },
    },
    adminGetAllFestivals: {
      method: "GET",
      path: "/allbyAdmin",
      query: z.object({
        festivalsViewType: z.nativeEnum(FestivalsViewType),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetAllFestivalsResponse),
      },
    },
    getFestivalsResponses: {
      method: "GET",
      path: "/responses",
      query: z.object({
        festivalsId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetFestivalsResponses),
      },
    },
    getFestivalsPageDetails: {
      method: "GET",
      path: "/pageDetails",
      query: z.object({
        festivalsId: z.string(),
        inviteId: z.string().optional(),
      }),
      responses: {
        200: GetFestivalsPageDetailsResponse,
      },
    },
    addFestivalsRequestResponse: {
      method: "POST",
      path: "/response",
      body: FestivalsRequestResponse,
      responses: { 200: SuccessSchema.extend({ calendarLink: z.string() }) },
    },
    getFestivalsTypeCount: {
      method: "GET",
      path: "/typeCount",
      responses: {
        200: FestivalsTypeCountResponse,
      },
    },
    adminGetFestivalsTypeCount: {
      method: "GET",
      path: "/typeCountByAdmin",
      responses: {
        200: FestivalsTypeCountResponse,
      },
    },
  },
  {
    pathPrefix: "/festivals",
  }
);
